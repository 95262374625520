import React, { useState, useEffect } from 'react';
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import { useLocation  } from "react-router-dom";

export default function Layout({ children, homeData }) {
  const [isGetApplePage, setIsGetApplePage] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  var applePages = {
    "/apple": true,
    "/apple/enterprise/": true,
    "/apple/enterprise/applecare/": true,
    "/apple/mac-does-that/": true
  }

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    } 
  }; 
  useEffect(() => {
    setIsGetApplePage(!!applePages[location.pathname]);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname]);

  return (
    <>
      <div className={`header ${isGetApplePage ? "apple-header" : ""} ${isScrolled ? 'stuck' : ''}`}>
        <Header homeData={homeData} />
      </div>
      <div className="inner-wrap">
        {children}
      </div>
      <div className="footer">
        <Footer homeData={homeData} />
      </div>
    </>
  )
}