import React, { useEffect, useState } from "react";
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import ClientExperiences from "../../components/home/client-experiences";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import WhatWeOffer from "../../components/CommonSections/WhatWeOffer";
import Advantage from "../../components/CommonSections/Advantage";
import PageLoader from "../../components/home/pageload";
import MetaTag from "../../components/CommonSections/MetaTag";
import ImgSmartCity from "../../assest/images/IshanSmartCity.png";
import ImgDedicatedLeasedLine from "../../assest/images/dedicated-leased-line-ic.svg";
import ImgOfficeInternet from "../../assest/images/office-internet-ic.svg";
import ImgIshanLeasedLine from "../../assest/images/ishan-leased-line-ic.svg";
import ImgMac from "../../assest/images/Mac.png";
import ImgSec1 from "../../assest/images/mdt-sec-1.png";
import ImgSec2 from "../../assest/images/mdt-sec-2.png";
import ImgSec3 from "../../assest/images/mdt-sec-3.png";
import ImgSec4 from "../../assest/images/mdt-sec-4.png";
import ImgSec5 from "../../assest/images/mdt-sec-5.png";
import ImgSec6 from "../../assest/images/mdt-sec-6.png";
import macbook1 from "../../assest/images/macbook-1.png";
import macbook2 from "../../assest/images/macbook-2.png";
import macbook3 from "../../assest/images/macbook-3.png";
import macbook4 from "../../assest/images/macbook-4.png";
import macbook5 from "../../assest/images/macbook-5.png";
import macbook6 from "../../assest/images/macbook-6.png";
import resIc1 from "../../assest/images/res-ic-1.png";
import resIc2 from "../../assest/images/res-ic-2.png";
import resIc3 from "../../assest/images/res-ic-3.png";
import resIc4 from "../../assest/images/res-ic-4.png";
import resIc5 from "../../assest/images/res-ic-5.png";
import resIc6 from "../../assest/images/res-ic-6.png";


import ImgBanner1 from "../../assest/images/banner2-img.png";
import AppleIc from "../../assest/images/apple-ic.png";
import PlansPopup from "../contacts/plans-popup";

import { Link } from "react-router-dom";
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";
import ColocationPopup from "../../components/colocation-popup";
import Accordion from '../../components/accordion';
import InternetServiceSec from "../../components/interner-service";
import {
     Input,
     FormFeedback,
     Form, 
     Modal,
     ModalBody,
   } from "reactstrap";

export default function TestPage() {
     const handleClick = () => {
          window.scrollTo(0, 0);
     }

     const [InternetService, setInternetService] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);

     useEffect(() => {
          const requestData = {  
               "service_tag": "apple_services_mac_does_that"
          };

          axios.post(`${API_BASE_URL}/api/smartcity/getsmartCity`, requestData).then((response) => {
               setInternetService(response);

               console.log('response',response);
               const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;
               const InternetServicesSettingsData = generalSettingsData.find((item) => item.tag === "smart_city_advantage") || null;
               const InternetServicesSettingsResponse = JSON.parse(InternetServicesSettingsData?.response || "{}");

               InternetServicesSettingsResponse.advantages = InternetServicesSettingsResponse;
               setSettingResponse(InternetServicesSettingsResponse);
          }).catch((error) => { 
               <PageLoader />
          })
     }, [])

     const [modal, setModal] = useState(false);
     const [colocationData, setcolocationData] = useState(null);
     const toggle = () => {
       if (modal) {
         setModal(false);
       } else {
         setModal(true);
       }
     };
   
   
     const openModal = (colocationData) => {
       setModal(true);
       setcolocationData(colocationData);
     };   


     const [getPlanModal, setPlanModal] = useState(false);
     const [planData, setPlanData] = useState(null);
     const planToggle = () => {
       if (getPlanModal) {
          setPlanModal(false);
       } else {
          setPlanModal(true);
       }
     };
    
   
     const openPlanModal = (planData) => {
          setPlanModal(true);
          setPlanModal(planData);
     };
   

     const [getInTuchPlanModal, setInTuchPlanModal] = useState(false);
     const planGetIntuchToggle = () => {
       if (getInTuchPlanModal) {
          setInTuchPlanModal(false);
       } else {
          setInTuchPlanModal(true);
       }
     };
    
   
     const openGetInTuchPlanModal = () => {
          setInTuchPlanModal(true);
     };
   

     return (

          <>
               {InternetService === null ? (
                    <PageLoader />
               ) : (
                    <>
                    <SubServiceSchema title={InternetService?.data?.data?.smart_city?.smart_city_banner_data
                              [0].title} url='https://ishantechnologies.com/systems-integration/smart-city-services' parentTitle='System Integration' parentUrl='https://ishantechnologies.com/systems-integration' />
                    <MetaTag data={InternetService} pageKey="smart_city"/>
                    
                         <div 
                              className={`video hero-banner internet-banner ${
                              !InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                                                      [0]?.banner_title &&!InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                                                      [0]?.banner_img_desc 
                                   ? 'no-content' 
                                   : ''
                              }`}
                              >
                              <FrontVideo homeData={InternetService} pageKey="smart_city" />

                              <div className="banner-content apple-does-that-banner">
                                   
                                   <div className="content-blk">
                                        <div className="banner-body">
                                             <img src={ImgMac} alt="" className="img-unset mb-5" />
                                             <h1 className="title">
                                                  <div>Work <span>SMARTER</span></div>
                                                  <div>Go <span>FASTER</span></div>
                                                  <div>Stress <span>LESS</span></div>
                                             </h1>
                                             <img src={ImgBanner1} alt="" className="img-unset mb-4" />
                                             <h5 className="title">
                                                  <div>Apple Does <span>That</span></div>
                                             </h5>
                                             <h6 className="f-20">Your Enterprise. Your Needs. Apple with Ishan – Uniquely Yours</h6>
                                        </div>
                                   

                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.button_img_name && (
                                             <a href={InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].button_url}>
                                                  <button className="button hero-btn">
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.button_img_name}
                                                  </button>
                                             </a>
                                             )}
                                   </div>
                                   <div className="form-blk">
                                        <InternetServiceSec homeData={InternetService} pageName="mac-does-that" />
                                   </div>
                              </div>  
                         </div>

                         <div className="empowering section-space spin-wrap bg-light">
                              <div className="spin-round-wrap">
                                   <div className="spin-round color-navy-blue">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>

                              <div className="container container-mdt">
                                   <div className="adt-box box-one mb-4">
                                        <div className="row align-items-center justify-content-between">
                                             <div className="col-md-5">
                                                  <div className="txt-blk text-center p-5">
                                                       <div className="title">
                                                       Multitask <span>SUPERFAST</span> 
                                                       with the M2 chip®
                                                       </div>
                                                       <p className="para">
                                                       The unified memory architecture of Apple silicon delivers fast, fluid multitasking.
                                                       </p>
                                                  </div>
                                             </div>
                                             <div className="col-md-6">
                                                  <div className="right-img">
                                                       <img src={ImgSec1} alt="Multitask SUPERFASTwith the M2 chip" className="ml-auto" />
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="adt-box box-two mb-4">
                                        <div className="row align-items-center justify-content-center">
                                             <div className="col-md-6">
                                                  <div className="txt-blk text-center p-5">
                                                       <div className="title">
                                                       The looooongest &nbsp; 
                                                        <span>BATTERY</span> 
                                                       ever in a Mac
                                                       </div>
                                                       <p className="para">
                                                       With Mac, you can go non-stop on a single charge.
                                                       Get up to 21 hours on MacBook Pro 16”.
                                                       </p>
                                                  </div>
                                             </div>
                                             <div className="col-md-12">
                                                  <div className="right-img">
                                                       <img src={ImgSec2} alt="" className="ml-auto" />
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="adt-box box-three mb-4">
                                        <div className="row align-items-center justify-content-between">
                                             <div className="col-md-5">
                                                  <div className="txt-blk text-center p-5">
                                                       <div className="title">
                                                            <span>EXCEL</span> 
                                                            runs and runs and runs on Mac
                                                       </div>
                                                       <p className="para">
                                                       Mac runs the apps you need like Microsoft 365, Outlook, Google Workspace, and more.
                                                       </p>
                                                  </div>
                                             </div>
                                             <div className="col-md-6">
                                                  <div className="right-img ">
                                                       <img src={ImgSec3} alt="EXCEL runs on Mac" className="" />
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="adt-box box-four mb-4">
                                        <div className="row align-items-center justify-content-between">
                                        <div className="col-md-6">
                                                  <div className="right-img ">
                                                       <img src={ImgSec4} alt=" Mac built-in tools" className="m-auto" />
                                                  </div>
                                             </div>
                                             <div className="col-md-6">
                                                  <div className="txt-blk text-center p-5">
                                                       <div className="title">
                                                            Super &nbsp;
                                                             <span>SECURE</span> 
                                                             out of the box
                                                       </div>
                                                       <p className="para">
                                                       With Mac, features like on-the-fly encryption, Touch ID, and Gatekeeper are built-in — and it’s compatible with the security tools you already use.
                                                       </p>
                                                  </div>
                                             </div>
                                             
                                        </div>
                                   </div>
                                   <div className="adt-box box-five mb-4">
                                        <div className="row align-items-center justify-content-between">
                                        <div className="col-md-6">
                                                  <div className="txt-blk text-center p-5">
                                                       <div className="title">
                                                       The <span>EASIEST</span> 
                                                       computer to manage
                                                       </div>
                                                       <p className="para">
                                                       With faster setup and fewer support tickets, the IT value adds up.
                                                       </p>
                                                  </div>
                                             </div>
                                        <div className="col-md-6">
                                                  <div className="right-img ">
                                                       <img src={ImgSec5} alt="" className="m-auto" />
                                                  </div>
                                             </div>
                                             
                                             
                                        </div>
                                   </div>
                                   <div className="adt-box box-six mb-4">
                                        <div className="row align-items-center justify-content-between">
                                        <div className="col-md-6">
                                                  <div className="right-img ">
                                                       <img src={ImgSec6} alt="" className="" />
                                                  </div>
                                             </div>
                                             <div className="col-md-6">
                                                  <div className="txt-blk text-center p-5">
                                                       <div className="title">
                                                       Mac + iPhone &nbsp;<span>BETTER TOGETHER</span> 
                                                         
                                                       </div>
                                                       <p className="para">
                                                       Copy and paste, sync edits, and continue conversations between Mac and iPhone.
                                                       </p>
                                                  </div>
                                             </div>
                                             
                                        </div>
                                   </div>

                                   <div className="resources-box mb-4">
                                        <h2 className="heading text-center">Which Mac is right <br /> for your business?</h2>

                                        <div className="row">
                                             <div className="col-md-6 col-lg-4 res-card-wrap">
                                                  <div className="res-card">
                                                       <div className="res-card-img">
                                                            <img src={macbook1} alt="" />
                                                       </div>
                                                       <div className="title">MacBook Air (M1)</div>
                                                       <div className="sub-title">Power. It’s in the Air.</div>
                                                       <p className="para">33.74 cm (13.3-inch) Retina display*</p>
                                                       <p className="para">Powered by the Apple M1 chip</p>
                                                       <p className="para">Up to 18 hours of battery life*</p>
                                                       <p className="para">Up to 16GB unified memory</p>
                                                       <a href="javascript:void(0)" className="btn-text" onClick={() => openPlanModal('I want to enquire about the MacBook Air (M1).')}>Buy Now</a>
                                                  </div>
                                             </div>
                                             <div className="col-md-6 col-lg-4 res-card-wrap">
                                                  <div className="res-card">
                                                       <div className="res-card-img">
                                                            <img src={macbook2} alt="" />
                                                       </div>
                                                       <div className="title">MacBook Air (M2)</div>
                                                       <div className="sub-title">Don’t take it lightly.</div>
                                                       <p className="para">34.46 cm (13.6-inch) Liquid Retina display*</p>
                                                       <p className="para">Powered by the Apple M2 chip</p>
                                                       <p className="para">Up to 18 hours of battery life*</p>
                                                       <p className="para">Up to 24GB unified memory</p>
                                                       <a href="javascript:void(0)" className="btn-text" onClick={() => openPlanModal('I want to enquire about the MacBook Air (M2).')}>Buy Now </a>
                                                  </div>
                                             </div>
                                             <div className="col-md-6 col-lg-4 res-card-wrap">
                                                  <div className="res-card">
                                                       <div className="res-card-img">
                                                            <img src={macbook3} alt="" />
                                                       </div>
                                                       <div className="title">MacBook Pro 13”</div>
                                                       <div className="sub-title">Pro anywhere.</div>
                                                       <p className="para">33.74 cm (13.3-inch) Retina display*</p>
                                                       <p className="para">Powered by the Apple M2 chip</p>
                                                       <p className="para">Up to 20 hours of battery life*</p>
                                                       <p className="para">Up to 24GB unified memory</p>
                                                       <a href="javascript:void(0)" className="btn-text" onClick={() => openPlanModal('I want to enquire about the MacBook Pro 13”.')}>Buy Now </a>
                                                  </div>
                                             </div>
                                             <div className="col-md-6 col-lg-4 res-card-wrap">
                                                  <div className="res-card">
                                                       <div className="res-card-img">
                                                            <img src={macbook4} alt="" />
                                                       </div>
                                                       <div className="title">MacBook Pro 14” and 16”
                                                       </div>
                                                       <div className="sub-title">Supercharged for pros.</div>
                                                       <p className="para">35.97 cm (14.2-inch) or 40.65 cm (16.2-inch) Liquid Retina XDR display*</p>
                                                       <p className="para">Powered by the Apple M1 Pro chip or Apple M1 Max chip</p>
                                                       <p className="para">Up to 22 hours of battery life*</p>
                                                       <p className="para">Up to 64GB unified memory</p>
                                                       <a href="javascript:void(0)" className="btn-text" onClick={() => openPlanModal('I want to enquire about the MacBook Pro 14” and 16”.')}>Buy Now </a>
                                                  </div>
                                             </div>
                                             <div className="col-md-6 col-lg-4 res-card-wrap">
                                                  <div className="res-card">
                                                       <div className="res-card-img">
                                                            <img src={macbook5} alt="" />
                                                       </div>
                                                       <div className="title">iMac 24”</div>
                                                       <div className="sub-title">All-in-one. All in colour.</div>
                                                       <p className="para">60.96 cm (24-inch) 4.5K Retina display*</p>
                                                       <p className="para">Powered by the Apple M1 chip</p>
                                                       <p className="para">Up to 16GB unified memory</p>
                                                       <a href="javascript:void(0)" className="btn-text"  onClick={() => openPlanModal('I want to enquire about the iMac 24”.')}>Buy Now </a>
                                                  </div>
                                             </div>
                                             <div className="col-md-6 col-lg-4 res-card-wrap">
                                                  <div className="res-card">
                                                       <div className="res-card-img last">
                                                            <img src={macbook6} alt="" className="mt-auto" />
                                                       </div>
                                                       <div className="title">Mac mini</div>
                                                       <div className="sub-title">Huge performance. Mini footprint.</div>
                                                       <p className="para">Powered by the Apple M1 chip</p>
                                                       <p className="para">Also available with Intel Core i5 or i7 processor
                                                       </p>
                                                       <p className="para">Up to 16GB unified memory</p>
                                                       <a href="javascript:void(0)" className="btn-text"onClick={() => openPlanModal('I want to enquire about the Mac mini.')}>Buy Now </a>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>

                                   {/* <div className="resources-box ">
                                        <h2 className="heading text-center">Resources</h2>

                                        <div className="row">
                                             <div className="col-md-6 col-lg-4 res-card-wrap">
                                                  <div className="res-card res">
                                                       <div className="res-card-img">
                                                            <img src={resIc1} alt="" />
                                                       </div>
                                                       <div className="title">AppleCare <br /> Professional Support</div>
                                                       <a href="javascript:void(0)" className="btn-text">Learn more </a>
                                                  </div>
                                             </div>
                                             <div className="col-md-6 col-lg-4 res-card-wrap">
                                                  <div className="res-card res">
                                                       <div className="res-card-img">
                                                            <img src={resIc2} alt="" />
                                                       </div>
                                                       <div className="title">Deployment and <br /> Management Guide</div>
                                                       <a href="javascript:void(0)" className="btn-text">Learn more </a>
                                                  </div>
                                             </div>
                                             <div className="col-md-6 col-lg-4 res-card-wrap">
                                                  <div className="res-card res">
                                                       <div className="res-card-img">
                                                            <img src={resIc3} alt="" />
                                                       </div>
                                                       <div className="title">Apple <br /> Financial Services </div>
                                                       <a href="javascript:void(0)" className="btn-text">Learn more </a>
                                                  </div>
                                             </div>
                                             <div className="col-md-6 col-lg-4 res-card-wrap">
                                                  <div className="res-card res">
                                                       <div className="res-card-img">
                                                            <img src={resIc4} alt="" />
                                                       </div>
                                                       <div className="title">Employee Choice<br /> Guide for IT</div>
                                                       <a href="javascript:void(0)" className="btn-text">Learn more </a>
                                                  </div>
                                             </div>
                                             <div className="col-md-6 col-lg-4 res-card-wrap">
                                                  <div className="res-card res">
                                                       <div className="res-card-img">
                                                            <img src={resIc5} alt="" />
                                                       </div>
                                                       <div className="title">Platform <br />Security Guide </div>
                                                       <a href="javascript:void(0)" className="btn-text">Learn more </a>
                                                  </div>
                                             </div>
                                             <div className="col-md-6 col-lg-4 res-card-wrap">
                                                  <div className="res-card res">
                                                       <div className="res-card-img">
                                                            <img src={resIc6} alt="" />
                                                       </div>
                                                       <div className="title">A Smart  <br /> Investment</div>
                                                       <a href="javascript:void(0)" className="btn-text">Learn more </a>
                                                  </div>
                                             </div>
                                             
                                        </div>

                                        <h2 className="heading text-center mb-4">Let's get started</h2>
                                        <h5 className="sub-head text-center">Talk to an expert to learn more <br /> about Mac for work</h5>

                                        <a href="/contact" class="button btn-pacific-blue InternetLeaseLine ">Get in touch</a>
                                        
                                   </div> */}

                                   <div className="para-box">
                                        <p className="para">◊Legal Disclaimers</p>
                                        <p className="para">
                                            <span className="fw-600">M2 chip:</span> M2 chip is available in selected models.
                                        </p>
                                        <p className="para">
                                            <span className="fw-600">Power and Battery:</span> 
                                            Testing conducted by Apple in September 2021 using pre-production 14-inch MacBook Pro systems with Apple M1 Pro, 8-core CPU, 14-core GPU, 16GB of RAM and 512GB SSD; and pre-production 16-inch MacBook Pro systems with Apple M1 Pro, 10-core CPU, 16-core GPU, 16GB of RAM and 1TB SSD. The Apple TV app movie playback 
                                            test measures battery life by playing back 1080p HD content with display brightness set to 8 clicks from the bottom. Battery life varies by use and configuration. See <a href="https://www.apple.com/in/batteries/">apple.com/in/batteries</a> for more information.
                                        </p>
                                        <p className="para">
                                        Testing conducted by Apple in October 2020 using pre-production MacBook Air systems with Apple M1 chip and 8-core GPU, configured with 8GB of RAM and 512GB SSD. 
                                        </p>
                                        <p className="para">
                                        The Apple TV app movie playback test measures battery life by playing back 1080p HD content with display brightness set to 8 clicks from the bottom. Battery life varies by use 
                                        and configuration. See <a href="https://www.apple.com/in/batteries/">apple.com/in/batteries</a> for more information.
                                        </p>
                                        <p className="para">
                                        Testing conducted by Apple in May 2022 using pre-production 13‑inch MacBook Pro systems with Apple M2, 8‑core CPU, 8‑core GPU, 8GB of RAM and 256GB SSD. 
                                        </p>
                                        <p className="para">
                                        The wireless web test measures battery life by wirelessly browsing 25 popular websites with display brightness set to 8 clicks from the bottom. The Apple TV app movie playback test measures battery life by playing back 1080p HD content with display brightness set to 8 clicks from the bottom. Battery life varies by use and configuration. See <a href="https://www.apple.com/in/batteries/">apple.com/in/batteries</a> for more information.
                                        </p>
                                        <p className="para">
                                             <span className="fw-600">App Store:</span>
                                             Apps are available on the App Store. Title availability is subject to change. Microsoft 365 subscription is required.
                                        </p>
                                        <p className="para">
                                             <span className="fw-600">Mac and iPhone:</span>
                                             Requires iCloud and Internet connection.
                                        </p>
                                        <p className="para">
                                             <span>Value:</span>
                                             Based on data from companies interviewed in Forrester Total Economic Impact™ of Mac in Enterprise: M1 Update, commissioned by Apple, published in July 2021 <a href="https://tei.forrester.com/go/apple/tei/">(https://tools.totaleconomicimpact.com/go/apple/tei)</a>. Companies should use their own estimates within the framework provided in the Forrester study to understand their own
                                             cost and time savings.
                                        </p>
                                        <p className="para">
                                             <span className="fw-600">Display:</span>
                                             Screen size is measured diagonally. Actual diagonal screen size of the 24-inch iMac is 59.69 centimetres (23.5 inches). The displays on the 13.6-inch MacBook Air and
                                             the 14-inch and 16-inch MacBook Pro have rounded corners at the top. When measured as a standard rectangular shape, the screens are 34.46 centimetres (13.6 inches), 
                                             35.97 centimetres (14.2 inches) and 41.05 centimetres (16.2 inches) diagonally (actual viewable area is less).
                                        </p>
                                        <p className="para">
                                             <span className="fw-600">Mac mini:</span>
                                             Intel-based model is available with up to 64GB memory.
                                        </p>
                                   </div>
                              </div>

                              <div className="spin-round-wrap">
                                   <div className="spin-round">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>
                         </div>

                         
                         <div className="supercharge supercharge-left">
                              <div className="container">
                                   <h3 className="supercharge-heading" style={{marginBottom:'2px', textAlign:'center'}}>{InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.title}</h3>

                                   <div className="supercharge-btns"  style={{textAlign:'center'}}>
                                        {InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_1 &&
                                             <a href="javascript:void(0)" onClick={() => openPlanModal(' ')}>
                                                  <button className="getintouch"  onClick={handleClick}>{InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_1?.toUpperCase()}</button>
                                             </a>
                                        }
                                       
                                        {InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_2 &&
                                             <a href="javascript:void(0)" onClick={() => openPlanModal(' ')}>
                                                  <button className="getintouch" onClick={handleClick}>{InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_2?.toUpperCase()}</button>
                                             </a>
                                        }
                                   </div>
                              </div>
                         </div>
                         
                         <Modal isOpen={modal} toggle={toggle}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); toggle(); }}>×</Link>
                              <ColocationPopup colocationData={colocationData} pageName="InternetLeaseLine" />
                              </ModalBody>
                         </Modal>

                         <Modal isOpen={getPlanModal} toggle={planToggle}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); planToggle(); }}>×</Link>
                                   <ColocationPopup colocationData={colocationData} planDetails={getPlanModal} pageName="mac-does-that" />
                              </ModalBody>
                         </Modal>
                         
                         <Modal isOpen={getInTuchPlanModal} toggle={getInTuchPlanModal}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); planGetIntuchToggle(); }}>×</Link>
                                   <div className="Mobile_getin_tuch">
                                      <InternetServiceSec homeData={InternetService} pageName="mac-does-that" />
                                   </div>
                              </ModalBody>
                         </Modal>

                         <a href="javascript:void(0)" className="enquire-now-side-btn" onClick={() => openGetInTuchPlanModal()}>GET IN TOUCH</a>

                    </>
               )} 
          </>
     );
}