import React, { useEffect, useState } from "react";
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import ClientExperiences from "../../components/home/client-experiences";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import WhatWeOffer from "../../components/CommonSections/WhatWeOffer";
import Advantage from "../../components/CommonSections/Advantage";
import PageLoader from "../../components/home/pageload";
import MetaTag from "../../components/CommonSections/MetaTag";
import ImgSmartCity from "../../assest/images/IshanSmartCity.png";
import ImgDedicatedLeasedLine from "../../assest/images/dedicated-leased-line-ic.svg";
import ImgOfficeInternet from "../../assest/images/office-internet-ic.svg";
import ImgIshanLeasedLine from "../../assest/images/ishan-leased-line-ic.svg";
import PlansPopup from "../contacts/plans-popup";
import ImgCloudPlatformManaged from "../../assest/images/IshanCloud Platform Managed.png";

import { Link } from "react-router-dom";
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";
import ColocationPopup from "../../components/colocation-popup";
import Accordion from '../../components/accordion';
import InternetServiceSec from "../../components/interner-service";
import {
     Input,
     FormFeedback,
     Form, 
     Modal,
     ModalBody,
   } from "reactstrap";

export default function TestPage() {
     const handleClick = () => {
          window.scrollTo(0, 0);
     }

     const [InternetService, setInternetService] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);

     useEffect(() => {
          const requestData = {  
               "service_tag": "cloud_plans"
          };

          axios.post(`${API_BASE_URL}/api/smartcity/getsmartCity`, requestData).then((response) => {
               setInternetService(response);

               console.log('response',response);
               const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;
               const InternetServicesSettingsData = generalSettingsData.find((item) => item.tag === "smart_city_advantage") || null;
               const InternetServicesSettingsResponse = JSON.parse(InternetServicesSettingsData?.response || "{}");

               InternetServicesSettingsResponse.advantages = InternetServicesSettingsResponse;
               setSettingResponse(InternetServicesSettingsResponse);
          }).catch((error) => { 
               <PageLoader />
          })
     }, [])

     const [modal, setModal] = useState(false);
     const [colocationData, setcolocationData] = useState(null);
     const toggle = () => {
       if (modal) {
         setModal(false);
       } else {
         setModal(true);
       }
     };
   
   
     const openModal = (colocationData) => {
       setModal(true);
       setcolocationData(colocationData);
     };   


     const [getPlanModal, setPlanModal] = useState(false);
     const [planData, setPlanData] = useState(null);
     const planToggle = () => {
       if (getPlanModal) {
          setPlanModal(false);
       } else {
          setPlanModal(true);
       }
     };
    
   
     const openPlanModal = (planData) => {
          setPlanModal(true);
          setPlanModal(planData);
     };
   

     const [getInTuchPlanModal, setInTuchPlanModal] = useState(false);
     const planGetIntuchToggle = () => {
       if (getInTuchPlanModal) {
          setInTuchPlanModal(false);
       } else {
          setInTuchPlanModal(true);
       }
     };
    
   
     const openGetInTuchPlanModal = () => {
          setInTuchPlanModal(true);
     };
   

     return (

          <>
               {InternetService === null ? (
                    <PageLoader />
               ) : (
                    <>
                    <SubServiceSchema title={InternetService?.data?.data?.smart_city?.smart_city_banner_data
                              [0].title} url='https://ishantechnologies.com/systems-integration/smart-city-services' parentTitle='System Integration' parentUrl='https://ishantechnologies.com/systems-integration' />
                    <MetaTag data={InternetService} pageKey="smart_city"/>
                         <div className="page_heading responsive_heading">
                              <div className="container">
                                   <HeaderTitle headerTitledata={InternetService} pageKey="smart_city" />
                              </div>
                         </div>
                         <div 
                              className={`video hero-banner internet-banner ${
                              !InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                                                      [0]?.banner_title &&!InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                                                      [0]?.banner_img_desc 
                                   ? 'no-content' 
                                   : ''
                              }`}
                              >
                              <FrontVideo homeData={InternetService} pageKey="smart_city" />

                              <div className="banner-content">
                                   <div className="content-blk">
                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.banner_title && (
                                             <h2 className='home-heading'>
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].banner_title}
                                             </h2>
                                             )}

                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.banner_img_desc && (
                                             <p className="para">
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].banner_img_desc}
                                             </p>
                                             )}

                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.button_img_name && (
                                             <a href={InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].button_url}>
                                                  <button className="button hero-btn">
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.button_img_name}
                                                  </button>
                                             </a>
                                             )}
                                   </div>
                                   <div className="form-blk">
                                        <InternetServiceSec homeData={InternetService} pageName="InternetLeaseLine" />
                                   </div>
                                   
                              </div>  
                         </div>

                         <div className="empowering section-space spin-wrap pb-5">
                              <div className="spin-round-wrap">
                                   <div className="spin-round color-navy-blue">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>

                              
                              <div className="container">
                                   <div class="empowering-inner">
                                        <div class="empowering-left">
                                        <img src={ImgCloudPlatformManaged} alt="Ishan Cloud Platform" height="60" width="60" loading="lazy" />
                                        </div>
                                        <div class="empowering-right">
                                             <h2 className="text-primary">Ishan Cloud Platform</h2>

                                             <div className="f-18 mb-4">Scalable. Secure. Cost-Effective.</div>
                                             <div className="text-primary f-24">Choose the Right Plan for Your Business</div>
                                             <p className="para f-18 mb-0">
                                             At Ishan Technologies, we offer cloud solutions that fit your needs, whether you require high computing power, enhanced memory, or reliable storage.
                                             </p>
                                             
                                        </div>
                                   </div>
                              </div>
                              <div className="spin-round-wrap">
                                   <div className="spin-round">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>
                         </div>

                         <section className="plans-table-section desk_plan_sec cloud pt-0">
                              <div className="container">
                                   
                              <div className="type-heading">Ishan Cloud Server - General Category</div>
                                   {/* table one  */}
                                   <div className="table-box">
                                        <div className="table-heading">General Category (Linux Server) - MONTHLY COST</div>
                                        <div className="table-responsive">
                                             <table className="plans-table table-bordered">
                                                  <thead>
                                                       <tr>
                                                            <th>2vCPU</th>
                                                            <th>4vCPU</th>
                                                            <th>6vCPU</th>
                                                            <th>8vCPU</th>
                                                            <th>12vCPU</th>
                                                            <th>24vCPU</th>
                                                            <th>32vCPU</th>
                                                            <th>48vCPU</th>
                                                       </tr>
                                                  </thead>
                                                  <tbody>
                                                       <tr>
                                                            <td>4 GB Memory</td>
                                                            <td>8 GB Memory</td>
                                                            <td>16 GB Memory</td>
                                                            <td>32 GB Memory</td>
                                                            <td>64 GB Memory</td>
                                                            <td>96 GB Memory</td>
                                                            <td>128 GB Memory</td>
                                                            <td>192 GB Memory</td>
                                                       </tr>
                                                       <tr>
                                                            <td>100 GB Disk</td>
                                                            <td>200 GB Disk</td>
                                                            <td>300 GB Disk</td>
                                                            <td>500 GB Disk</td>
                                                            <td>750 GB Disk</td>
                                                            <td>1000 GB Disk</td>
                                                            <td>1250 GB Disk</td>
                                                            <td>1500 GB Disk</td>
                                                       </tr>
                                                       <tr>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                       </tr>
                                                       <tr>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                           
                                                            
                                                       </tr>
                                                       <tr>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                           
                                                       </tr>
                                                  </tbody>
                                                  <tfoot>
                                                       <tr>
                                                            <th>2630+ 18% - Month/Cost</th>
                                                            <th>4510+ 18% - Month/Cost</th>
                                                            <th>7110+ 18% - Month/Cost</th>
                                                            <th>11950+ 18% - Month/Cost</th>
                                                            <th>20430+ 18% - Month/Cost</th>
                                                            <th>30350+ 18% - Month/Cost</th>
                                                            <th>39550+ 18% - Month/Cost</th>
                                                            <th>55950+ 18% - Month/Cost</th>
                                                       </tr>
                                                  </tfoot>
                                             </table>
                                        </div>
                                   </div>

                                   {/* table two  */}
                                   <div className="table-box">
                                        <div className="table-heading">General Category (Windows Server) - MONTHLY COST</div>
                                        <div className="table-responsive">
                                             <table className="plans-table table-bordered">
                                                  <thead>
                                                       <tr>
                                                            <th>2vCPU</th>
                                                            <th>4vCPU</th>
                                                            <th>6vCPU</th>
                                                            <th>8vCPU</th>
                                                            <th>12vCPU</th>
                                                            <th>24vCPU</th>
                                                            <th>32vCPU</th>
                                                            <th>48vCPU</th>
                                                       </tr>
                                                  </thead>
                                                  <tbody>
                                                       <tr>
                                                            <td>4 GB Memory</td>
                                                            <td>8 GB Memory</td>
                                                            <td>16 GB Memory</td>
                                                            <td>32 GB Memory</td>
                                                            <td>64 GB Memory</td>
                                                            <td>96 GB Memory</td>
                                                            <td>128 GB Memory</td>
                                                            <td>192 GB Memory</td>
                                                       </tr>

                                                       <tr>
                                                            <td>100 GB Disk </td>
                                                            <td>200 GB Disk </td>
                                                            <td>300 GB Disk</td>
                                                            <td>500 GB Disk </td>
                                                            <td>750 GB Disk </td>
                                                            <td>1000 GB Disk </td>
                                                            <td>1250 GB Disk </td>
                                                            <td>1500 GB Disk </td>
                                                       </tr>

                                                       <tr>
                                                            <td>2TB Bandwidth* </td>
                                                            <td>2TB Bandwidth* </td>
                                                            <td>2TB Bandwidth* </td>
                                                            <td>2TB Bandwidth* </td>
                                                            <td>2TB Bandwidth* </td>
                                                            <td>2TB Bandwidth* </td>
                                                            <td>2TB Bandwidth* </td>
                                                            <td>2TB Bandwidth* </td>
                                                       </tr>
                                                       <tr>
                                                            <td>1 No, IP Address </td>
                                                            <td>1 No, IP Address </td>
                                                            <td>1 No, IP Address </td>
                                                            <td>1 No, IP Address </td>
                                                            <td>1 No, IP Address </td>
                                                            <td>1 No, IP Address </td>
                                                            <td>1 No, IP Address </td>
                                                            <td>1 No, IP Address </td>
                                                       </tr>
                                                       <tr>
                                                            <td>Windows Server OS </td>
                                                            <td>Windows Server OS </td>
                                                            <td>Windows Server OS </td>
                                                            <td>Windows Server OS </td>
                                                            <td>Windows Server OS </td>
                                                            <td>Windows Server OS </td>
                                                            <td>Windows Server OS </td>
                                                            <td>Windows Server OS </td>
                                                       </tr>

                                                  </tbody>
                                                  <tfoot>
                                                       <tr>
                                                            <th>3230+ 18% - Month/Cost</th>
                                                            <th>5710+ 18% - Month/Cost</th>
                                                            <th>8910+ 18% - Month/Cost</th>
                                                            <th>14350+ 18% - Month/Cost</th>
                                                            <th>24030+ 18% - Month/Cost</th>
                                                            <th>37550+ 18% - Month/Cost</th>
                                                            <th>49150+ 18% - Month/Cost</th>
                                                            <th>70350+ 18% - Month/Cost</th>
                                                       </tr>
                                                  </tfoot>
                                             </table>
                                        </div>
                                   </div>

                                   <div className="type-heading">Ishan Cloud Server - High vCPU Category</div>

                                   {/* table three  */}
                                   <div className="table-box">
                                        <div className="table-heading">High vCPU (Linux Server) - MONTHLY COST</div>
                                        <div className="table-responsive">
                                             <table className="plans-table table-bordered">
                                                  <thead>
                                                       <tr>
                                                            <th>4vCPU</th>
                                                            <th>8vCPU</th>
                                                            <th>16vCPU</th>
                                                            <th>32vCPU</th>
                                                            <th>64vCPU</th>
                                                            <th>96vCPU</th>
                                                            <th>128vCPU</th>
                                                       </tr>
                                                  </thead>
                                                  <tbody>
                                                       <tr>
                                                            <td>4 GB Memory</td>
                                                            <td>8 GB Memory</td>
                                                            <td>16 GB Memory</td>
                                                            <td>32 GB Memory</td>
                                                            <td>64 GB Memory</td>
                                                            <td>96 GB Memory</td>
                                                            <td>128 GB Memory</td>
                                                       </tr>

                                                       <tr>
                                                            <td>100 GB Disk</td>
                                                            <td>200 GB Disk</td>
                                                            <td>300 GB Disk</td>
                                                            <td>500 GB Disk</td>
                                                            <td>750 GB Disk</td>
                                                            <td>1000 GB Disk</td>
                                                            <td>1250 GB Disk</td>
                                                       </tr>

                                                       <tr>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                       </tr>

                                                       <tr>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            
                                                       </tr>

                                                       <tr>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                         
                                                       </tr>

                                                  </tbody>
                                                  <tfoot>
                                                       <tr>
                                                            <th>2990+ 18% - Month/Cost</th>
                                                            <th>5230+ 18% - Month/Cost</th>
                                                            <th>8910+ 18% - Month/Cost</th>
                                                            <th>16270+ 18% - Month/Cost   </th>
                                                            <th>29790+ 18% - Month/Cost</th>
                                                            <th>43310+ 18% - Month/Cost</th>
                                                            <th>56830+ 18% - Month/Cost</th>
                                                       </tr>
                                                  </tfoot>
                                             </table>
                                        </div>
                                   </div>

                                   {/* table four  */}
                                   <div className="table-box">
                                        <div className="table-heading">High vCPU (Windows Server)  - MONTHLY COST</div>
                                        <div className="table-responsive">
                                             <table className="plans-table table-bordered">
                                                  <thead>
                                                       <tr>
                                                            <th>4vCPU</th>
                                                            <th>8vCPU</th>
                                                            <th>16vCPU</th>
                                                            <th>32vCPU</th>
                                                            <th>64vCPU</th>
                                                            <th>96vCPU</th>
                                                            <th>128vCPU</th>
                                                       </tr>
                                                  </thead>
                                                  <tbody>
                                                       <tr>
                                                            <td>4 GB Memory</td>
                                                            <td>8 GB Memory</td>
                                                            <td>16 GB Memory</td>
                                                            <td>32 GB Memory</td>
                                                            <td>64 GB Memory</td>
                                                            <td>96 GB Memory</td>
                                                            <td>128 GB Memory</td>
                                                       </tr>

                                                       <tr>
                                                            <td>100 GB Disk</td>
                                                            <td>200 GB Disk</td>
                                                            <td>300 GB Disk</td>
                                                            <td>500 GB Disk</td>
                                                            <td>750 GB Disk</td>
                                                            <td>1000 GB Disk</td>
                                                            <td>1250 GB Disk</td>
                                                       </tr>

                                                       <tr>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                       </tr>

                                                       <tr>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                       </tr>

                                                       <tr>
                                                            <td>Windows Server OS</td>
                                                            <td>Windows Server OS</td>
                                                            <td>Windows Server OS</td>
                                                            <td>Windows Server OS</td>
                                                            <td>Windows Server OS</td>
                                                            <td>Windows Server OS</td>
                                                            <td>Windows Server OS</td>
                                                       </tr>

                                                  </tbody>
                                                  <tfoot>
                                                       <tr>
                                                            <th>4190+ 18% - Month/Cost</th>
                                                            <th>7630+ 18% - Month/Cost </th>
                                                            <th>13710+ 18% - Month/Cost </th>
                                                            <th>25870+ 18% - Month/Cost </th>
                                                            <th>48890+ 18% - Month/Cost </th>
                                                            <th>72110+ 18% - Month/Cost </th>
                                                            <th>95230+ 18% - Month/Cost </th>
                                                       </tr>
                                                  </tfoot>
                                             </table>
                                        </div>
                                   </div>

                                   <div className="type-heading">Ishan Cloud Server - High vCPU Category</div>
                                   {/* table five  */}
                                   <div className="table-box">
                                        <div className="table-heading">High Memory (Linux Server)</div>
                                        <div className="table-responsive">
                                             <table className="plans-table table-bordered">
                                                  <thead>
                                                       <tr>
                                                            <th>1vCPU</th>
                                                            <th>2vCPU</th>
                                                            <th>4vCPU</th>
                                                            <th>6vCPU</th>
                                                            <th>8vCPU</th>
                                                            <th>16vCPU</th>
                                                            <th>32vCPU</th>
                                                       </tr>
                                                  </thead>
                                                  <tbody>
                                                       <tr>
                                                            <td>8 GB Memory</td>
                                                            <td>16 GB Memory</td>
                                                            <td>32 GB Memory</td>
                                                            <td>32 GB Memory</td>
                                                            <td>64 GB Memory</td>
                                                            <td>128 GB Memory</td>
                                                            <td>196 GB Memory</td>
                                                       </tr>

                                                       <tr>
                                                            <td>100 GB Disk</td>
                                                            <td>200 GB Disk</td>
                                                            <td>300 GB Disk</td>
                                                            <td>500 GB Disk</td>
                                                            <td>750 GB Disk</td>
                                                            <td>1000 GB Disk</td>
                                                            <td>1250 GB Disk</td>
                                                        
                                                       </tr>

                                                       <tr>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                  
                                                       </tr>

                                                       <tr>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                      
                                                       </tr>

                                                       <tr>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                            <td>Linux (Freeware)</td>
                                                            
                                                       </tr>

                                                  </tbody>
                                                  <tfoot>
                                                       <tr>
                                                            <th>3170+ 18% - Month/Cost</th>
                                                            <th>5590+ 18% - Month/Cost </th>
                                                            <th>9630+ 18% - Month/Cost </th>
                                                            <th>11590+ 18% - Month/Cost </th>
                                                            <th>19710+ 18% - Month/Cost </th>
                                                            <th>34670+ 18% - Month/Cost </th>
                                                            <th>51790+ 18% - Month/Cost </th>
                                                       </tr>
                                                  </tfoot>
                                             </table>
                                        </div>
                                   </div>

                                   {/* table six  */}
                                   <div className="table-box">
                                        <div className="table-heading">High Memory (Windows Server)</div>
                                        <div className="table-responsive">
                                             <table className="plans-table table-bordered">
                                                  <thead>
                                                       <tr>
                                                            <th>1vCPU</th>
                                                            <th>2vCPU</th>
                                                            <th>4vCPU</th>
                                                            <th>6vCPU</th>
                                                            <th>8vCPU</th>
                                                            <th>16vCPU</th>
                                                            <th>32vCPU</th>
                                                          
                                                       </tr>
                                                  </thead>
                                                  <tbody>
                                                       <tr>
                                                            <td>8 GB Memory</td>
                                                            <td>16 GB Memory</td>
                                                            <td>32 GB Memory</td>
                                                            <td>32 GB Memory</td>
                                                            <td>64 GB Memory</td>
                                                            <td>128 GB Memory</td>
                                                            <td>196 GB Memory</td>
                                                       </tr>

                                                       <tr>
                                                            <td>100 GB Disk</td>
                                                            <td>200 GB Disk</td>
                                                            <td>300 GB Disk</td>
                                                            <td>500 GB Disk</td>
                                                            <td>750 GB Disk</td>
                                                            <td>1000 GB Disk</td>
                                                            <td>1250 GB Disk</td>
                                                       </tr>

                                                       <tr>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                            <td>2TB Bandwidth*</td>
                                                       </tr>

                                                       <tr>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                            <td>1 No, IP Address</td>
                                                       </tr>

                                                       <tr>
                                                            <td>Windows Server OS</td>
                                                            <td>Windows Server OS</td>
                                                            <td>Windows Server OS</td>
                                                            <td>Windows Server OS</td>
                                                            <td>Windows Server OS</td>
                                                            <td>Windows Server OS</td>
                                                            <td>Windows Server OS</td>
                                                       </tr>

                                                  </tbody>
                                                  <tfoot>
                                                       <tr>
                                                            <th>3470+ 18% - Month/Cost</th>
                                                            <th>6190+ 18% - Month/Cost</th>
                                                            <th>10830+ 18% - Month/Cost</th>
                                                            <th>13390+ 18% - Month/Cost</th>
                                                            <th>22110+ 18% - Month/Cost</th>
                                                            <th>39470+ 18% - Month/Cost</th>
                                                            <th>61390+ 18% - Month/Cost</th>
                                                           
                                                       </tr>
                                                  </tfoot>
                                             </table>
                                        </div>
                                   </div>



                                   <div className="type-heading">Storage & Backup Solutions</div>
                                   
                                   {/* table 7 */}
                                   <div className="table-box">
                                        <div className="table-heading">S3 Category Services</div>
                                        <div className="table-responsive">
                                             <table className="plans-table table-bordered">
                                                  <thead>
                                                       <tr>
                                                            <th>Size</th>
                                                            <th>Bacup-as-a-Service with 1 Agent</th>
                                                            <th>Object-as-a-Service</th>
                                                            <th>File-folder-as-a-Service</th>
                                                       </tr>
                                                  </thead>
                                                  <tbody>
                                                       <tr>
                                                            <td>1 TB</td>
                                                            <td>7,432</td>
                                                            <td>5,632</td>
                                                            <td>10,132</td>
                                                       </tr>
                                                       <tr>
                                                            <td>2 TB</td>
                                                            <td>13,064</td>
                                                            <td>11,264</td>
                                                            <td>15,764</td>
                                                       </tr>
                                                       <tr>
                                                            <td>5 TB</td>
                                                            <td>29,960</td>
                                                            <td>28,160</td>
                                                            <td>32,660</td>
                                                       </tr>
                                                       <tr>
                                                            <td>10 TB</td>
                                                            <td>58,120</td>
                                                            <td>56,320</td>
                                                            <td>60,820</td>
                                                       </tr>
                                                       <tr>
                                                            <td>15 TB</td>
                                                            <td>86,280</td>
                                                            <td>84,480</td>
                                                            <td>88,980</td>
                                                       </tr>
                                                  </tbody>
                                             </table>
                                        </div>
                                   </div>
                                   <div className="">
                                        <div className="f-18 mb-1">*GST Extra as Applicable (18%)</div>
                                        <div className="f-18 mb-1">*Above Mentioned commercials are Monthly Basic Only</div>
                                        <div className="f-18 mb-1">*1 Year Contract Period</div>
                                        <div className="f-18 mb-4">*Above Mentioned Commercials are Indicative</div>
                                   </div>
                                   <div className="f-24 text-primary mb-4 " >
                                        Custom Plans Available – Contact our team for tailored cloud solutions.
                                   </div>
                                   <a class="button" href="" >Get Started</a>
                                   <a href="" className="text-primary ml-4"> Experience Ishan Cloud Today!</a>
                              </div>
                         </section>



                         <section className="mob_plan_sec">
                              <div className="container">

                                   {/* table-1 content */}
                                   <div className="f-16 fw-500 text-primary mb-4">Ishan Cloud Server - General Category</div>
                                   <div className="type-heading">General Category (Linux Server) - MONTHLY COST</div>
                                   <div class="plans-wrap cloud-plan">
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">2vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>4 GB Memory</li>
                                                            <li>100 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Linux (Freeware)</li>  
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">2630+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">4vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>8 GB Memory</li>
                                                            <li>200 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Linux (Freeware)</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">4510+ 18% - Month/Cost</div>
                                             </div>
                                        </div>


                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">6vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>16 GB Memory</li>
                                                            <li>300 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Linux (Freeware)</li>
                                                       
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">7110+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">8vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>32 GB Memory</li>
                                                            <li>500 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Linux (Freeware)</li>
                                                       
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">11950+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">12vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>64 GB Memory</li>
                                                            <li>750 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Linux (Freeware)</li>
                                                       
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">20430+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">24vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>96 GB Memory</li>
                                                            <li>1000 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Linux (Freeware)</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">30350+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">32vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>128 GB Memory</li>
                                                            <li>1250 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Linux (Freeware)</li>
                                                       
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot"></div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">48vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>192 GB Memory</li>
                                                            <li>1500 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Linux (Freeware)</li>
                                                       
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">55950+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                   </div>

                                   {/* table-2 content */}
                                   <div className="type-heading">General Category (Windows Server) - MONTHLY COST</div>
                                   <div class="plans-wrap cloud-plan">
                                        <div class="plan-item">
          <div class="plan-inner">
               <div className="head">2vCPU</div>
               <div className="card-bdy">
                    <ul>
                         <li>4 GB Memory</li>
                         <li>100 GB Disk</li>
                         <li>2TB Bandwidth*</li>
                         <li>1 No, IP Address</li>
                         <li>Windows Server OS</li>
                    
                    </ul>
               </div>
               <div className="card-foot">3230+ 18% - Month/Cost</div>
          </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">4vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>8 GB Memory</li>
                                                            <li>200 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Windows Server OS</li>
                                                       
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">5710+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">6vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>16 GB Memory</li>
                                                            <li>300 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Windows Server OS</li>
                                                       
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">8910+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">8vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>32 GB Memory</li>
                                                            <li>500 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Windows Server OS</li>
                                                       
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">14350+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">12vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>64 GB Memory</li>
                                                            <li>750 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Windows Server OS</li>
                                                       
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">24030+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">24vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>96 GB Memory</li>
                                                            <li>1000 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Windows Server OS</li>
                                                       
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">37550+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">32vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>128 GB Memory</li>
                                                            <li>1250 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Windows Server OS</li>
                                                       
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">49150+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">48vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>192 GB Memory</li>
                                                            <li>1500 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Windows Server OS</li>
                                                       
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">70350+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                   </div>

                                   {/* table-3 content */}
                                   <div className="f-16 fw-500 text-primary mb-4">Ishan Cloud Server - High vCPU Category</div>
                                   <div className="type-heading">High vCPU (Linux Server) - MONTHLY COST</div>
                                   <div class="plans-wrap cloud-plan">
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">4vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>4 GB Memory</li>
                                                            <li>100 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Linux (Freeware)</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">2990+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">8vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>8 GB Memory</li>
                                                            <li>200 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Linux (Freeware)</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">5230+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">16vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>16 GB Memory</li>
                                                            <li>300 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Linux (Freeware)</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">8910+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">32vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>32 GB Memory</li>
                                                            <li>500 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Linux (Freeware)</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">16270+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">64vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>64 GB Memory</li>
                                                            <li>750 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Linux (Freeware)</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">29790+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">96vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>96 GB Memory</li>
                                                            <li>1000 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Linux (Freeware)</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">43310+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">128vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>128 GB Memory</li>
                                                            <li>1250 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Linux (Freeware)</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">56830+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                   </div>

                                   {/* table-4 content */}
                                   <div className="type-heading">High vCPU (Windows Server)  - MONTHLY COST</div>
                                   <div class="plans-wrap cloud-plan">
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">4vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>4 GB Memory</li>
                                                            <li>100 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Windows Server OS</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">4190+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">8vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>8 GB Memory</li>
                                                            <li>200 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Windows Server OS</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">7630+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">16vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>16 GB Memory</li>
                                                            <li>300 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Windows Server OS</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">13710+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">32vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>32 GB Memory</li>
                                                            <li>500 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Windows Server OS</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">25870+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">64vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>64 GB Memory</li>
                                                            <li>750 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Windows Server OS</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">48890+ 18% - Month/Cost</div>
                                             </div>
                                        </div>

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">96vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>96 GB Memory</li>
                                                            <li>1000 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Windows Server OS</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">72110+ 18% - Month/Cost</div>
                                             </div>
                                        </div>


                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">128vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>128 GB Memory</li>
                                                            <li>1250 GB Disk</li>
                                                            <li>2TB Bandwidth*</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>Windows Server OS</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">95230+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                   </div>

                                   {/* table-5 content */}
                                   <div className="f-16 fw-500 text-primary mb-4">Ishan Cloud Server - High Memory Category</div>
                                   <div className="type-heading">High Memory (Linux Server)</div>
                                   <div class="plans-wrap cloud-plan">
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">1vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>8 GB Memory</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>100 GB Disk</li>
                                                            <li>Linux (Freeware)</li>
                                                            <li>2TB Bandwidth*</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">3170+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">2vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>16 GB Memory</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>200 GB Disk</li>
                                                            <li>Linux (Freeware)</li>
                                                            <li>2TB Bandwidth*</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">5590+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">4vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>32 GB Memory</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>300 GB Disk</li>
                                                            <li>Linux (Freeware)</li>
                                                            <li>2TB Bandwidth*</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">9630+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">6vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>32 GB Memory</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>500 GB Disk</li>
                                                            <li>Linux (Freeware)</li>
                                                            <li>2TB Bandwidth*</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">11590+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">8vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>64 GB Memory</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>750 GB Disk</li>
                                                            <li>Linux (Freeware)</li>
                                                            <li>2TB Bandwidth*</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">19710+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">16vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>128 GB Memory</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>1000 GB Disk</li>
                                                            <li>Linux (Freeware)</li>
                                                            <li>2TB Bandwidth*</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">34670+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">32vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>196 GB Memory</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>1250 GB Disk</li>
                                                            <li>Linux (Freeware)</li>
                                                            <li>2TB Bandwidth*</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">51790+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                   </div>
                                   {/* table-6 content */}
                                   <div className="type-heading">High Memory (Windows Server)</div>
                                   <div class="plans-wrap cloud-plan">
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">1vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>8 GB Memory</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>100 GB Disk</li>
                                                            <li>Linux (Freeware)</li>
                                                            <li>2TB Bandwidth*</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">3470+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">2vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>16 GB Memory</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>200 GB Disk</li>
                                                            <li>Linux (Freeware)</li>
                                                            <li>2TB Bandwidth*</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">6190+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">4vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>32 GB Memory</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>300 GB Disk</li>
                                                            <li>Linux (Freeware)</li>
                                                            <li>2TB Bandwidth*</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">10830+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">6vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>32 GB Memory</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>500 GB Disk</li>
                                                            <li>Linux (Freeware)</li>
                                                            <li>2TB Bandwidth*</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">13390+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">8vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>64 GB Memory</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>750 GB Disk</li>
                                                            <li>Linux (Freeware)</li>
                                                            <li>2TB Bandwidth*</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">22110+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">16vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>128 GB Memory</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>1000 GB Disk</li>
                                                            <li>Linux (Freeware)</li>
                                                            <li>2TB Bandwidth*</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">39470+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">32vCPU</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li>196 GB Memory</li>
                                                            <li>1 No, IP Address</li>
                                                            <li>1250 GB Disk</li>
                                                            <li>Linux (Freeware)</li>
                                                            <li>2TB Bandwidth*</li>
                                                       </ul>
                                                  </div>
                                                  <div className="card-foot">61390+ 18% - Month/Cost</div>
                                             </div>
                                        </div>
                                   </div>

                                   {/* table-7 content */}
                                   <div className="f-16 fw-500 text-primary mb-4">Storage & Backup Solutions</div>
                                   <div className="type-heading">S3 Category Services</div>
                                   <div class="plans-wrap cloud-plan">
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">1 TB</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li className="w-100">Bacup-as-a-Service with 1 Agent : 7,432</li>
                                                            <li className="w-100">Object-as-a-Service : 5,632</li>
                                                            <li className="w-100">File-folder-as-a-Service : 10,132</li>
                                                       </ul>
                                                  </div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">2 TB</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li className="w-100">Bacup-as-a-Service with 1 Agent : 13,064</li>
                                                            <li className="w-100">Object-as-a-Service : 11,264</li>
                                                            <li className="w-100">File-folder-as-a-Service : 15,764</li>
                                                       </ul>
                                                  </div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">5 TB</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li className="w-100">Bacup-as-a-Service with 1 Agent : 29,960</li>
                                                            <li className="w-100">Object-as-a-Service : 28,160</li>
                                                            <li className="w-100">File-folder-as-a-Service : 32,660</li>
                                                       </ul>
                                                  </div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">10 TB</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li className="w-100">Bacup-as-a-Service with 1 Agent : 58,120</li>
                                                            <li className="w-100">Object-as-a-Service : 56,320</li>
                                                            <li className="w-100">File-folder-as-a-Service : 60,820</li>
                                                       </ul>
                                                  </div>
                                             </div>
                                        </div>
                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <div className="head">15 TB</div>
                                                  <div className="card-bdy">
                                                       <ul>
                                                            <li className="w-100">Bacup-as-a-Service with 1 Agent : 86,280</li>
                                                            <li className="w-100">Object-as-a-Service : 84,480</li>
                                                            <li className="w-100">File-folder-as-a-Service : 88,980</li>
                                                       </ul>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>

                                   <div className="">
                                        <div className=" mb-1">*GST Extra as Applicable (18%)</div>
                                        <div className=" mb-1">*Above Mentioned commercials are Monthly Basic Only</div>
                                        <div className=" mb-1">*1 Year Contract Period</div>
                                        <div className=" mb-4">*Above Mentioned Commercials are Indicative</div>
                                   </div>
                                   <div className="f-24 text-primary mb-4 " >
                                        Custom Plans Available – Contact our team for tailored cloud solutions.
                                   </div>
                                   <a class="button" href="" >Get Started</a>
                                   <br />
                                   <br />
                                   <a href="" className="text-primary"> Experience Ishan Cloud Today!</a>
                                   <br />
                                   <br />
                                   <br />
                              </div>
                         </section>
             

                         <Modal isOpen={modal} toggle={toggle}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); toggle(); }}>×</Link>
                              <ColocationPopup colocationData={colocationData} pageName="InternetLeaseLine" />
                              </ModalBody>
                         </Modal>

                         <Modal isOpen={getPlanModal} toggle={planToggle}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); planToggle(); }}>×</Link>
                                   <ColocationPopup colocationData={colocationData} planDetails={getPlanModal} pageName="InternetLeaseLine" />
                              </ModalBody>
                         </Modal>

                         <Modal isOpen={getInTuchPlanModal} toggle={getInTuchPlanModal}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); planGetIntuchToggle(); }}>×</Link>
                                   <div className="Mobile_getin_tuch">
                                      <InternetServiceSec homeData={InternetService} pageName="InternetLeaseLine" />
                                   </div>
                              </ModalBody>
                         </Modal>

                         <a href="javascript:void(0)" className="enquire-now-side-btn" onClick={() => openGetInTuchPlanModal()}>GET IN TOUCH</a>

                    </>
               )} 
          </>
     );
}