import React, { useEffect, useState } from "react";
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import ClientExperiences from "../../components/home/client-experiences";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import WhatWeOffer from "../../components/CommonSections/WhatWeOffer";
import Advantage from "../../components/CommonSections/Advantage";
import PageLoader from "../../components/home/pageload";
import MetaTag from "../../components/CommonSections/MetaTag";
import ImgSmartCity from "../../assest/images/IshanSmartCity.png";
import ImgDedicatedLeasedLine from "../../assest/images/dedicated-leased-line-ic.svg";
import ImgOfficeInternet from "../../assest/images/office-internet-ic.svg";
import ImgIshanLeasedLine from "../../assest/images/ishan-leased-line-ic.svg";
import ImgSec1 from "../../assest/images/apple-sec-1.png";
import ImgBanner1 from "../../assest/images/apple-banner-1.png";
import ImgSec2 from "../../assest/images/apple-sec-2.png";
import AppleIc from "../../assest/images/apple-ic.png";
import costOwn from "../../assest/images/apple-rs.png";
import PlansPopup from "../contacts/plans-popup";

import { Link } from "react-router-dom";
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";
import ColocationPopup from "../../components/colocation-popup";
import Accordion from '../../components/accordion';
import InternetServiceSec from "../../components/interner-service";
import {
     Input,
     FormFeedback,
     Form, 
     Modal,
     ModalBody,
   } from "reactstrap";

export default function TestPage() {
     const handleClick = () => {
          window.scrollTo(0, 0);
     }

     const [InternetService, setInternetService] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);

     useEffect(() => {
          const requestData = {  
               "service_tag": "apple_enterprise"
          };

          axios.post(`${API_BASE_URL}/api/smartcity/getsmartCity`, requestData).then((response) => {
               setInternetService(response);

               console.log('response',response);
               const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;
               const InternetServicesSettingsData = generalSettingsData.find((item) => item.tag === "smart_city_advantage") || null;
               const InternetServicesSettingsResponse = JSON.parse(InternetServicesSettingsData?.response || "{}");

               InternetServicesSettingsResponse.advantages = InternetServicesSettingsResponse;
               setSettingResponse(InternetServicesSettingsResponse);
          }).catch((error) => { 
               <PageLoader />
          })
     }, [])

     const [modal, setModal] = useState(false);
     const [colocationData, setcolocationData] = useState(null);
     const toggle = () => {
       if (modal) {
         setModal(false);
       } else {
         setModal(true);
       }
     };
   
   
     const openModal = (colocationData) => {
       setModal(true);
       setcolocationData(colocationData);
     };   


     const [getPlanModal, setPlanModal] = useState(false);
     const [planData, setPlanData] = useState(null);
     const planToggle = () => {
       if (getPlanModal) {
          setPlanModal(false);
       } else {
          setPlanModal(true);
       }
     };
    
   
     const openPlanModal = (planData) => {
          setPlanModal(true);
          setPlanModal(planData);
     };
   

     const [getInTuchPlanModal, setInTuchPlanModal] = useState(false);
     const planGetIntuchToggle = () => {
       if (getInTuchPlanModal) {
          setInTuchPlanModal(false);
       } else {
          setInTuchPlanModal(true);
       }
     };
    
   
     const openGetInTuchPlanModal = () => {
          setInTuchPlanModal(true);
     };
   

     return (

          <>
               {InternetService === null ? (
                    <PageLoader />
               ) : (
                    <>
                    <SubServiceSchema title={InternetService?.data?.data?.smart_city?.smart_city_banner_data
                              [0].title} url='https://ishantechnologies.com/systems-integration/smart-city-services' parentTitle='System Integration' parentUrl='https://ishantechnologies.com/systems-integration' />
                    <MetaTag data={InternetService} pageKey="smart_city"/>
                    
                         <div 
                              className={`video hero-banner internet-banner ${
                              !InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                                                      [0]?.banner_title &&!InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                                                      [0]?.banner_img_desc 
                                   ? 'no-content' 
                                   : ''
                              }`}
                              >
                              <FrontVideo homeData={InternetService} pageKey="smart_city" />

                              <div className="banner-content apple-banner-2">
                                   
                                   <div className="content-blk">
                                   {/* <img src={ImgBanner1} alt="" className="banner-one-img" /> */}
                                   
                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                             [0]?.banner_img_desc && (
                                                  <p className="para f-30">
                                                       {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                             [0].banner_img_desc}
                                                  </p>
                                             )}
                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.banner_title && (
                                             <h2 className='home-heading'>
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].banner_title}
                                        </h2>
                                        // <h2 class="home-heading">Empower Your <br/> Workforce</h2>
                                             
                                             )}

                                             

                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.button_img_name && (
                                             <a href="javascript:void(0)" onClick={() => openPlanModal(' ')}>
                                                  <button className="button hero-btn mt-4">
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.button_img_name}
                                                  </button>
                                             </a>
                                             )}

                                   <div className="auth-text mt-4">
                                        <img src={AppleIc} alt="" />
                                        <div>Authorized Reseller</div>
                                   </div>
                                   </div>
                                   
                              </div>  
                         </div>

                         <div className="empowering section-space spin-wrap">
                              <div className="spin-round-wrap">
                                   <div className="spin-round color-navy-blue">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>

                              <div className="container apple-page">
                                   <div className="empowering-inner">
                                        <div className="empowering-right primary-title" dangerouslySetInnerHTML={{ __html: InternetService?.data?.data?.smart_city?.smart_city_information_data[0]?.description }}>
                                   </div>

                                   
                                   </div>
                                   <div class="row mt-5">
                                        <div class="col-md-6 shadow-sect shadow-pr">
                                             <img src={ImgSec1} className="img-fluid" alt="Workforce with Apple Devices" />
                                        </div>
                                        <div class="col-md-6 shadow-pl">
                                             <h2 className="fw-500">Flexible, Scalable & Hassle-Free</h2>
                                             <p className=" mb-2">Access the latest MacBooks without the burden of ownership through our Device as a Service (DaaS) model, enjoy:</p>
                                             
                                             <ul>
                                                  <li>M-series chips deliver fast performance and all-day battery life</li>
                                                  <li>Flexible terms to scale with your business needs</li>
                                                  <li>Managed solutions tailored to your requirements</li>
                                                  <li>Advanced security with features like Touch ID, Face ID, and end-to-end encryption</li>
                                                  <li>Optimized for business, offering smooth integration with essential apps</li>
                                             </ul>
                                             <a href="/apple/mac-does-that/" className="fw-bold link-arrow">
                                                  MAC Does that – Know more 
                                             </a>
                                        </div>
                                   </div>
                                   <div class="row mt-5 pt-5 flex-row-reverse">
                                        <div class="col-md-6 shadow-pl">
                                             <img src={ImgSec2} className="img-fluid" alt="Built for Apple intelligence" />
                                        </div>
                                        <div class="col-md-6 shadow-sect shadow-pr">
                                             <h2> Work Smarter, Work from Anywhere</h2>
                                             <p className="mb-2">Experience the cutting edge of mobile performance with:</p>
                                             <ul>
                                                  <li>Get the latest of iPhone models for your Enterprises with Lightning-fast A-series chips</li>
                                                  <li>Flexible terms for hassle-free upgrades and scaling.</li>
                                                  <li>Secure access with Face ID and biometric security features</li>
                                                  <li>Seamless integration within the Apple ecosystem</li>
                                                  <li>5G connectivity for fast, uninterrupted business communication</li>
                                             </ul>
                                             {/* <a href="/apple/mac-does-that/" className="fw-bold link-arrow">
                                                  Know More 
                                             </a> */}
                                        </div>
                                   </div>
                                   
                              </div>

                              <div className="spin-round-wrap">
                                   <div className="spin-round">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>
                         </div>
                         <div className="section-space bg-light pt-5">
                              <div className="container">
                              <div class="row">
                                        <div className="col-12">
                                             <h2>Mac vs. PC: The Total Cost of Ownership (TCO) Advantages
                                             </h2>
                                             <p class="mb-5">Apple’s MacBook in enterprise can give you</p>
                                        </div>
                                        <div className="row">
                                             <div className="col-lg-6">
                                                  <div className="row">
                                                       <div className="col-md-6 col-lg-3 mb-4 ">
                                                            <div className="adv-crd">
                                                                 <div className="title mb-3">186%</div>
                                                                 <div className="sub-title mb-2">ROI in 3 years</div>
                                                                 <p className="para">Through lower operational costs, higher productivity, and strong residual value</p>
                                                            </div>
                                                       </div>
                                                       <div className="col-md-6 col-lg-3 mb-4">
                                                            <div className="adv-crd">
                                                                 <div className="title mb-3">56%</div>
                                                                 <div className="sub-title mb-2">less energy than PC</div>
                                                                 <p className="para">Mac devices consume less energy than PCs, leading to reduced energy costs</p>
                                                            </div>
                                                       </div>
                                                       <div className="col-md-6 col-lg-3 mb-4">
                                                            <div className="adv-crd">
                                                                 <div className="title mb-3"> 30%</div>
                                                                 <div className="sub-title mb-2">retained value</div>
                                                                 <p className="para">Macs retain more value over 4 years than PCs, which retain only 15%, maximizing hardware refresh returns.</p>
                                                            </div>
                                                       </div>
                                                       <div className="col-md-6 col-lg-3 mb-4">
                                                            <div className="adv-crd">
                                                                 <div className="title mb-3">90%</div>
                                                                 <div className="sub-title mb-2">more secure</div>
                                                                 <p className="para">Reduced likelihood of data breach via lost or stolen assets with Mac</p>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="col-lg-6 col-md-12 position-relative">
                                                  <img src={costOwn} className="img-fluid costOwnImg" alt="Apple’s MacBook" />
                                             </div>
                                        </div>
                                        
                                        
                                        <div className="col-12">
                                             <div className="f-12">
                                            <a href="https://tei.forrester.com/go/apple/tei/?lang=en-us"> Source: The Total Economic Impact™ Of Mac In Enterprise - April 2024</a>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
          
                         <div className="client-experiences section-space">
                              <ClientExperiences homeData={InternetService} pageKey="smart_city" tag="smart_city" />
                         </div>
                         
                         <div className="supercharge supercharge-left">
                              <div className="container">
                                   <h3 className="supercharge-heading" style={{marginBottom:'2px', textAlign:'center'}}>{InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.title}</h3>

                                   <div className="supercharge-btns"  style={{textAlign:'center'}}>
                                        {InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_1 &&
                                             <a href="javascript:void(0)" onClick={() => openPlanModal(' ')}>
                                                  <button className="getintouch"  onClick={handleClick}>{InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_1?.toUpperCase()}</button>
                                             </a>
                                        }
                                       
                                        {InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_2 &&
                                             <a href="javascript:void(0)" onClick={() => openPlanModal(' ')}>
                                                  <button className="getintouch"  onClick={handleClick}>{InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_1?.toUpperCase()}</button>
                                             </a>
                                        }
                                   </div>
                              </div>
                         </div>
                         
                         <Modal isOpen={modal} toggle={toggle}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); toggle(); }}>×</Link>
                              <ColocationPopup colocationData={colocationData} pageName="InternetLeaseLine" />
                              </ModalBody>
                         </Modal>

                         <Modal isOpen={getPlanModal} toggle={planToggle}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); planToggle(); }}>×</Link>
                                   <ColocationPopup colocationData={colocationData} planDetails={getPlanModal} pageName="InternetLeaseLine" />
                              </ModalBody>
                         </Modal>

                         <Modal isOpen={getInTuchPlanModal} toggle={getInTuchPlanModal}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); planGetIntuchToggle(); }}>×</Link>
                                   <div className="Mobile_getin_tuch">
                                      <InternetServiceSec homeData={InternetService} pageName="InternetLeaseLine" />
                                   </div>
                              </ModalBody>
                         </Modal>

                         <a href="javascript:void(0)" className="enquire-now-side-btn" onClick={() => openGetInTuchPlanModal()}>GET IN TOUCH</a>

                    </>
               )} 
          </>
     );
}