import React, { useState,useEffect  } from "react";
import { Link, useLocation  } from "react-router-dom";
import LogoImage from "../../images/logo.png";
import LogoImageApple from "../../assest/images/apple-header-logo.png";
import Navigation from "../navigation";
import HamburgerMenu from "./hamburger";

export default function Header({ homeData }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isGetApplePage, setIsGetApplePage] = useState(false);

  const location = useLocation();
  var applePages = {
    "/apple": true,
    "/apple/": true,
    "/apple/enterprise": true,
    "/apple/enterprise/": true,
    "/apple/enterprise/applecare": true,
    "/apple/enterprise/applecare/": true,
    "/apple/mac-does-that": true,
    "/apple/mac-does-that/": true
  }

  //var isGetApplePage = !!applePages[window.location.pathname];
  var toggleMenu = () => {
    setIsOpen(!isOpen);
  }; 

  useEffect(() => {
    setIsGetApplePage(!!applePages[location.pathname]);
  }, [location.pathname]);

  return (
    <div className="container">
      <div className="h-lft">
        <Link to="/" className="logo">
        {isGetApplePage ?
        <img src={LogoImageApple} loading="lazy" height={39} className="img-fluid"/> :
        <img src={homeData?.data?.data?.header?.header_logo_data[0]?.primary_logo || LogoImage} alt={homeData?.data?.data?.header?.header_logo_data[0]?.altText || LogoImage} loading="lazy" height={39} width={305}/>
        }
          
        </Link>
      </div>
      <div className="h-rgt">
        <div className="h-navigation">
          <Navigation isOpen={isOpen} toggleMenu={toggleMenu} homeData={homeData} isApplePage={isGetApplePage}/>
        </div>
        <HamburgerMenu isOpen={isOpen} toggleMenu={toggleMenu} />
      </div>
    </div>
  );
}