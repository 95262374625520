import React, { useEffect, useState } from "react";
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import ClientExperiences from "../../components/home/client-experiences";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import WhatWeOffer from "../../components/CommonSections/WhatWeOffer";
import Advantage from "../../components/CommonSections/Advantage";
import PageLoader from "../../components/home/pageload";
import MetaTag from "../../components/CommonSections/MetaTag";
import ImgSmartCity from "../../assest/images/IshanSmartCity.png";
import ImgDedicatedLeasedLine from "../../assest/images/dedicated-leased-line-ic.svg";
import ImgOfficeInternet from "../../assest/images/office-internet-ic.svg";
import ImgIshanLeasedLine from "../../assest/images/ishan-leased-line-ic.svg";
import ImgSec1 from "../../assest/images/apple-care-sec-1.png";
import ImgSec2 from "../../assest/images/apple-care-sec-2.png";
import ImgSec3 from "../../assest/images/apple-care-sec-3.png";
import ImgSec4 from "../../assest/images/apple-care-sec-4.png";

import ImgBanner1 from "../../assest/images/apple-banner-1.png";
import AppleIc from "../../assest/images/apple-ic.png";
import PlansPopup from "../contacts/plans-popup";

import { Link } from "react-router-dom";
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";
import ColocationPopup from "../../components/colocation-popup";
import Accordion from '../../components/accordion';
import InternetServiceSec from "../../components/interner-service";
import {
     Input,
     FormFeedback,
     Form, 
     Modal,
     ModalBody,
   } from "reactstrap";

export default function TestPage() {
     const handleClick = () => {
          window.scrollTo(0, 0);
     }

     const [InternetService, setInternetService] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);

     useEffect(() => {
          const requestData = {  
               "service_tag": "apple_enterprise_applecare"
          };

          axios.post(`${API_BASE_URL}/api/smartcity/getsmartCity`, requestData).then((response) => {
               setInternetService(response);

               console.log('response',response);
               const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;
               const InternetServicesSettingsData = generalSettingsData.find((item) => item.tag === "smart_city_advantage") || null;
               const InternetServicesSettingsResponse = JSON.parse(InternetServicesSettingsData?.response || "{}");

               InternetServicesSettingsResponse.advantages = InternetServicesSettingsResponse;
               setSettingResponse(InternetServicesSettingsResponse);
          }).catch((error) => { 
               <PageLoader />
          })
     }, [])

     const [modal, setModal] = useState(false);
     const [colocationData, setcolocationData] = useState(null);
     const toggle = () => {
       if (modal) {
         setModal(false);
       } else {
         setModal(true);
       }
     };
   
   
     const openModal = (colocationData) => {
       setModal(true);
       setcolocationData(colocationData);
     };   


     const [getPlanModal, setPlanModal] = useState(false);
     const [planData, setPlanData] = useState(null);
     const planToggle = () => {
       if (getPlanModal) {
          setPlanModal(false);
       } else {
          setPlanModal(true);
       }
     };
    
   
     const openPlanModal = (planData) => {
          setPlanModal(true);
          setPlanModal(planData);
     };
   

     const [getInTuchPlanModal, setInTuchPlanModal] = useState(false);
     const planGetIntuchToggle = () => {
       if (getInTuchPlanModal) {
          setInTuchPlanModal(false);
       } else {
          setInTuchPlanModal(true);
       }
     };
    
   
     const openGetInTuchPlanModal = () => {
          setInTuchPlanModal(true);
     };
   

     return (

          <>
               {InternetService === null ? (
                    <PageLoader />
               ) : (
                    <>
                    <SubServiceSchema title={InternetService?.data?.data?.smart_city?.smart_city_banner_data
                              [0].title} url='https://ishantechnologies.com/systems-integration/smart-city-services' parentTitle='System Integration' parentUrl='https://ishantechnologies.com/systems-integration' />
                    <MetaTag data={InternetService} pageKey="smart_city"/>
                    
                         <div 
                              className={`video hero-banner internet-banner ${
                              !InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                                                      [0]?.banner_title &&!InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                                                      [0]?.banner_img_desc 
                                   ? 'no-content' 
                                   : ''
                              }`}
                              >
                              <FrontVideo homeData={InternetService} pageKey="smart_city" />

                              <div className="banner-content apple-banner-2">
                                   
                                   <div className="content-blk">
                                   
                                        <h5 className="f-30">Seamless Professional Support with</h5>
                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.banner_title && (
                                             <h2 className='home-heading'>
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                             [0].banner_title}
                                             </h2>
                                        // <div className="mb-4">
                                        //      <h5 className="care-subt">Comprehensive Support for Your System</h5>
                                        //      <h2 class="home-heading">Apple <br/> Ecosystem</h2>
                                        // </div>
                                             )}

                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.banner_img_desc && (
                                             <p className="para">
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].banner_img_desc}
                                             </p>
                                             )}

                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.button_img_name && (
                                             <a href="javascript:void(0)" onClick={() => openPlanModal(' ')}>
                                                  <button className="button hero-btn">
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.button_img_name}
                                                  </button>
                                             </a>
                                             
                                             )}
                                             <div className="auth-text mt-4">
                                             <img src={AppleIc} alt="" />
                                             <div>Authorised Reseller</div>
                                             </div>
                                   </div>
                                   
                              </div>  
                         </div>

                         <div className="empowering section-space spin-wrap">
                              <div className="spin-round-wrap">
                                   <div className="spin-round color-navy-blue">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>

                              <div className="container apple-page">
                                   <div className="empowering-inner">
                                        <div className="empowering-right primary-title"        dangerouslySetInnerHTML={{ __html: InternetService?.data?.data?.smart_city?.smart_city_information_data[0]?.description }}>
                                        </div>
                                   </div>

                                   <div class="row mt-5">
                                        <div class="col-md-6 shadow-sect shadow-pr">
                                             <img src={ImgSec1} className="img-fluid" alt="IT Department Support" />
                                        </div>
                                        <div class="col-md-6 shadow-pl">
                                             <h2 className="fw-500">IT Department <span className="text-highlight">Support</span></h2>
                                             <h5 className="text-primary">Expert Assistance for Your IT Team</h5>
                                             <p className="fw-500 mb-2">AppleCare for Enterprise provides your IT department with the tools and expertise needed to manage Apple devices effectively.</p>
                                             
                                             <ul>
                                                  <li>Technical Support: Access support for hardware, software, and complex deployment scenarios.</li>
                                                  <li>Deployment Assistance: Get guidance on integrating Apple </li>
                                                  <li>devices with existing systems, including MDM solutions and Active Directory.</li>
                                                  <li>Incident Management: Receive IT-level support for resolving critical issues quickly and effectively.</li>
                                             </ul>
                                        </div>
                                   </div>
                                   <div class="row mt-5 pt-5 flex-row-reverse">
                                        <div class="col-md-6 shadow-pl">
                                             <img src={ImgSec2} className="img-fluid" alt="AppleCare for Enterprise" />
                                        </div>
                                        <div class="col-md-6 shadow-sect shadow-pr">
                                        <h2 className="fw-500">End-User <span className="text-highlight">Support</span></h2>
                                             <h5 className="text-primary">Empowering Your Workforce with Reliable Support</h5>
                                             <p className="fw-500 mb-2">AppleCare for Enterprise helps reduce the load on your internal help desk by providing direct support for your employees.</p>
                                             
                                             <ul>
                                                  <li>Unlimited Technical Support: Employees have 24/7 access to Apple experts for troubleshooting Apple devices and apps.</li>
                                                  <li>Hardware Coverage: Repairs or replacement for covered Apple devices, including onsite service for critical issues.</li>
                                                  <li>Apple App Support: Assistance with Apple software.</li>
                                             </ul>
                                        </div>
                                   </div>
                                   <div class="row mt-5 pt-5">
                                        <div class="col-md-6 shadow-sect shadow-pr">
                                             <img src={ImgSec3} className="img-fluid" alt="Device Management and Security" />
                                        </div>
                                        <div class="col-md-6 shadow-pl">
                                             <h2 className="fw-500">Device <span className="text-highlight">Management</span> and <span className="text-highlight">Security</span></h2>
                                             <h5 className="text-primary">Ensure Smooth Operations Across Your Organization</h5>
                                             <p className="fw-500 mb-2">With AppleCare for Enterprise, your IT department can maintain control and compliance while enhancing security <a href="/apple/enterprise"> across all Apple devices </a> .</p>
                                             
                                             <ul>
                                                  <li>Centralized Device Management: Seamlessly configure, monitor, and manage Apple devices remotely.</li>
                                                  <li>Data Security: Benefit from encryption, secure authentication, and remote wipe capabilities to protect sensitive information. </li>
                                                  <li>Compliance Monitoring: Enforce security policies </li>
                                             </ul>
                                        </div>
                                   </div>
                                   <div class="row mt-5 pt-5 flex-row-reverse">
                                        <div class="col-md-6 shadow-pl">
                                             <img src={ImgSec4} className="img-fluid" alt=" Business Continuity and Efficiency" />
                                        </div>
                                        <div class="col-md-6 shadow-sect shadow-pr">
                                        <h2 className="fw-500">Business Continuity
                                        and <span className="text-highlight"> Efficiency</span></h2>
                                             <h5 className="text-primary">Tailored Solutions for Every Business Need</h5>
                                             <p className="fw-500 mb-2">AppleCare for Enterprise ensures your organization stays operational and efficient, providing services that scale with your business.</p>
                                             
                                             <ul>
                                                  <li>Customizable Plans: Choose support plans that align with your business goals and IT needs.</li>
                                                  <li>Priority Onsite Services: Minimize downtime with repairs and maintenance delivered directly at your location.</li>
                                             </ul>
                                        </div>
                                   </div>
                              </div>

                              <div className="spin-round-wrap">
                                   <div className="spin-round">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>
                         </div>

          
                         <div className="client-experiences section-space">
                              <ClientExperiences homeData={InternetService} pageKey="smart_city" tag="smart_city" />
                         </div>
                         
                         <div className="supercharge supercharge-left">
                              <div className="container">
                                   <h3 className="supercharge-heading" style={{marginBottom:'2px', textAlign:'center'}}>{InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.title}</h3>

                                   <div className="supercharge-btns"  style={{textAlign:'center'}}>
                                        {InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_1 &&
                                             <a href="javascript:void(0)" onClick={() => openPlanModal(' ')}>
                                                  <button className="getintouch"  onClick={handleClick}>{InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_1?.toUpperCase()}</button>
                                             </a>
                                        }
                                       
                                        {InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_2 &&
                                             <a href="javascript:void(0)" onClick={() => openPlanModal(' ')}>
                                                  <button className="getintouch" onClick={handleClick}>{InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_2?.toUpperCase()}</button>
                                             </a>
                                        }
                                   </div>
                              </div>
                         </div>
                         
                         <Modal isOpen={modal} toggle={toggle}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); toggle(); }}>×</Link>
                              <ColocationPopup colocationData={colocationData} pageName="InternetLeaseLine" />
                              </ModalBody>
                         </Modal>

                         <Modal isOpen={getPlanModal} toggle={planToggle}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); planToggle(); }}>×</Link>
                                   <ColocationPopup colocationData={colocationData} planDetails={getPlanModal} pageName="InternetLeaseLine" />
                              </ModalBody>
                         </Modal>

                         <Modal isOpen={getInTuchPlanModal} toggle={getInTuchPlanModal}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); planGetIntuchToggle(); }}>×</Link>
                                   <div className="Mobile_getin_tuch">
                                      <InternetServiceSec homeData={InternetService} pageName="InternetLeaseLine" />
                                   </div>
                              </ModalBody>
                         </Modal>

                         <a href="javascript:void(0)" className="enquire-now-side-btn" onClick={() => openGetInTuchPlanModal()}>GET IN TOUCH</a>

                    </>
               )} 
          </>
     );
}